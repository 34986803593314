import request from "@/utils/request";
import qs from "qs";
// 联系我们
export const insertContactUs = (data) => {
  return request({
    url: "wx/insertContactUs",
    method: "post",
    data: qs.stringify(data),
  });
};
// 联系我们列表
export const findContactUs = (params) => {
  return request({
    url: "wx/findContactUs",
    method: "get",
    params,
  });
};
