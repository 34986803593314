import request from "@/utils/request";
// 广告
export const oauthLogin = (params) => {
  return request({
    url: "wx/oauthLogin",
    method: "get",
    params,
  });
};
// 广告
export const findUserByOpenId = (params) => {
  return request({
    url: "wx/wx_findUserByOpenId",
    method: "get",
    params,
  });
};
// 修改用户信息
export const updateUserInfo = (data) => {
  return request({
    url: "wx/updateUserInfo",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 实用工具
export const getSpecificUrlByType = (params) => {
  return request({
    url: "wx/getSpecificUrlByType",
    method: "get",
    params,
  });
};
