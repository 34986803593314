<template>
  <div class="ContactUs">
    <div class="ContactUs_top">
      <van-image
        width="100%"
        fit="cover"
        :src="require('@/assets/img/ContactUs.jpg')"
      />

      <div class="ContactUs_form">
        <div class="address">
          <van-image
            width="100%"
            fit="cover"
            :src="require('@/assets/img/ContactUs_02.png')"
            @click="goMap"
          />
          <div class="address_cell">
            <span class="title">联系电话/Telephone</span>
            <a class="telephone" href="tel:010-8535-9744">010-8535-9744</a>
          </div>
          <div class="address_box">
            <div class="address_cell">
              <span class="title address_text">地址/Address</span>
              <span class="value">北京市朝阳区光华路7号汉威大厦西区12B5 </span>
            </div>
            <p class="address_english">
              Room 12B5,12th Floor,West Hanwei Building,7 Guanghua
              Road,Beijing,100004 China
            </p>
          </div>
          <div class="address_cell">
            <span class="title">电子邮件/E-mail</span>
            <a class="telephone" href="mailto:ciicfc@ciic.com.cn"
              >ciicfc@ciic.com.cn</a
            >
            <!-- <span >ciicfc@ciic.com.cn</span> -->
          </div>
        </div>
        <div class="placeholder"></div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.userName"
            input-align="right"
            name="userName"
            label="您的称呼"
            required
            :rules="[{ required: true }]"
            placeholder="请输入"
          />
          <van-field
            name="userSex"
            label="您的性别"
            input-align="right"
            required
            :rules="[{ required: true }]"
          >
            <template #input>
              <van-radio-group
                v-model="form.userSex"
                direction="horizontal"
                icon-size="15px"
              >
                <van-radio name="0">先生</van-radio>
                <van-radio name="1">女士</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.userCompany"
            required
            :rules="[{ required: true }]"
            input-align="right"
            name="userCompany"
            label="您所在的公司"
            placeholder="请输入"
          />
          <van-field
            v-model="form.answerPosition"
            input-align="right"
            name="answerPosition"
            label="您的职位"
            placeholder="请输入"
          />
          <van-field
            v-model="form.userCompanyScale"
            input-align="right"
            name="userCompanyScale"
            type="digit"
            label="您公司的规模"
            placeholder="请输入"
          />
          <div class="placeholder"></div>
          <van-field
            v-model="form.userPhone"
            name="userPhone"
            type="tel"
            placeholder="请输入手机号以便我们与您取得联系"
            class="placeholder_color"
            :rules="[
              { required: true, message: '请填写您的手机号码！' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
            ]"
          />

          <van-field
            v-model="phoneCode"
            center
            clearable
            type="digit"
            placeholder="请输入验证码"
            class="placeholder_color"
          >
            <template #button>
              <span class="code" v-show="timeTrue == true" @click="getCode"
                >获取验证码</span
              >
              <span class="code" v-show="timeTrue == false"
                >{{ time }}秒后重新获取</span
              >
              <!-- <span class="code" @click="getCode">获取验证码</span> -->
            </template>
          </van-field>
          <div class="next_step">
            <van-button block type="info" native-type="submit"
              >下一步</van-button
            >
          </div>
        </van-form>
        <copyrightIp />
      </div>
    </div>
  </div>
</template>
<script>
import {
  Form,
  Field,
  Button,
  Checkbox,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Notify,
  Image as VanImage,
} from "vant";
import { insertContactUs } from "@/api/ContactUs";
import { sendSMS } from "@/api/index";
// import { wxLogin } from "@/utils/login";
import { oauthLogin } from "@/api/PeopleCenter";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    copyrightIp,
  },
  data() {
    return {
      form: {
        userName: "", //名字
        userSex: "", //性别
        userCompany: "", //公司
        answerPosition: "", //职位
        userCompanyScale: "", //规模
        userPhone: "", // 手机号
        fromType: "0", // (0:联系我们  1粉丝提问)
        openId: "", //
      },
      phoneCode: "", //用户输入手机验证码
      servePhoneCode: "", // 服务器返回验证码
      timeTrue: true,
      time: 0,
    };
  },
  created() {
    // let httpUrl = decodeURI(window.location.href);
    // console.log(
    //   httpUrl,
    //   "我是路由",
    //   this.$route,
    //   ";=====",
    //   httpUrl.indexOf(window.location.search)
    // );
    const code = this.getUrlParam("code"); //是否存在code
    if (!localStorage.getItem("code")) {
      if (code) {
        oauthLogin({ code }).then((res) => {
          localStorage.setItem("code", res.data.data.openid);
        });
      } else {
        this.CodeBtn();
      }
    }

    // wxLogin();
  },
  methods: {
    // 获取url地址栏参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var rrr = decodeURIComponent(window.location.search);
      var r = rrr.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 静默获取code
    CodeBtn() {
      // let appid = "wxa59bd37481626cab"; //公众平台appid
      let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
      let local = encodeURIComponent(window.location.href); //获取当前url
      //不存在就打开上面的地址进行授权
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=wecedirect`;
    },
    goMap() {
      window.location.href =
        "https://apis.map.qq.com/uri/v1/search?keyword=中智薪税技术服务有限公司&region=北京&to=中智薪税技术服务有限公司&referer=QD7BZ-QMTCX-SHF4K-TLUMZ-AGNU2-74FKE";
    },

    onSubmit(values) {
      if (this.phoneCode && this.phoneCode == this.servePhoneCode) {
        this.form.openId = localStorage.getItem("code");
        insertContactUs(this.form).then((res) => {
          if (res.data.data == 1001) {
            this.$router.push("/contactUs/success");
          } else {
            Notify("联系失败请重试！!");
          }
        });
      } else {
        return Notify("输入的验证码有误!");
      }
    },
    getCode() {
      if (!this.form.userPhone) {
        return Notify("手机号不能为空");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(this.form.userPhone)) {
          this.timeTrue = false;
          this.time = 60;
          var setTimeoutS = setInterval(() => {
            this.time--;
            if (this.time <= 0) {
              clearInterval(setTimeoutS);
              this.timeTrue = true;
            }
          }, 1000);
          let phoneNum = this.form.userPhone;
          let openId = localStorage.getItem("code");
          sendSMS({ phoneNum, openId }).then((res) => {
            this.servePhoneCode = res.data.data;
          });
        } else {
          return Notify("请输入正确的手机号");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactUs {
  .ContactUs_top {
    position: relative;
    h2 {
      font-size: 20px;
      font-weight: bold;
      padding: 8px 10px;
    }
    h3 {
      font-weight: bold;
      font-size: 23px;
      padding-bottom: 10px;
      text-align: center;
    }
    .ContactUs_form {
      position: absolute;
      left: 0;
      top: 186px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      background: #fff;
      .address {
        width: 100%;
        padding: 20px 15px 0;
        .van-image {
          width: 100%;
          border-radius: 10px;
          padding-bottom: 15px;
          overflow: hidden;
        }
        .address_cell {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          padding: 10px 0;
          border-top: 1px solid #ebedf0;
          .title {
            min-width: 150px;
            color: #808492;
          }
          .address_text {
            min-width: 140px;
          }
          .telephone {
            color: blue;
          }
        }
        .address_english {
          padding-bottom: 10px;
          font-size: 16px;
        }
      }
      .code {
        color: #559eec;
        font-weight: bold;
      }
      .placeholder {
        width: 100%;
        height: 10px;
        background: #f4f6f6;
      }
      ::v-deep .van-form {
        padding: 0 8px;
        .van-cell {
          font-size: 16px;
          line-height: 34px;
          align-items: center;
        }
      }
      .placeholder_color {
        ::v-deep .van-field__body {
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #808492;
          }

          input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #808492;
          }

          input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #808492;
          }

          input:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #808492;
          }
        }
      }
    }
  }
  ::v-deep .van-field__control {
    .van-checkbox {
      margin-bottom: 10px;
    }
  }
  .tel_box {
    width: 100%;
    display: flex;
  }
  .next_step {
    margin: 16px;
    ::v-deep .van-button {
      border-radius: 5px;
    }
  }
}
</style>
